import {
  IScheduleCalendarService,
  IGetAllCalendarResponse,
  AvailableHoursByDateAndTeamIdRequest,
  AvailableHoursByDateAndTeamIdResponse,
  IGetResourceScheduleRequest,
  IGetResourceScheduleResponse,
  ICreateUpdateResourceScheduleRequest,
  IDefaultResponseModel,
  IRemoveResourceScheduleRequest,
} from "./scheduling-calendar.model";

import httpClient from "../../common/http/httpClient";
import { AxiosResponse } from "axios";

const ScheduleCalendarService = (): IScheduleCalendarService => {
  return {
    getScheduleCalendarList: (
      _objData: IGetResourceScheduleRequest
    ): HttpPromise<IGetAllCalendarResponse> => {
      return httpClient
        .post<IGetResourceScheduleResponse[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/scheduler/get-resource-schedule`,
          { data: _objData }
        )
        .then((res: AxiosResponse<IGetResourceScheduleResponse[], any>) => res);
    },
    saveScheduleCalendar: (
      _objData: ICreateUpdateResourceScheduleRequest
    ): HttpPromise<IDefaultResponseModel> => {
      return httpClient
        .post<IDefaultResponseModel>(
          `${process.env.REACT_APP_API_BASE_URL}/api/scheduler/create-update-resource-schedule`,
          { data: _objData }
        )
        .then((res: AxiosResponse<IDefaultResponseModel, any>) => res);
    },
    removeResourceSchedule: (
      objData: IRemoveResourceScheduleRequest
    ): HttpPromise<IDefaultResponseModel> => {
      return httpClient
        .post<IDefaultResponseModel>(
          `${process.env.REACT_APP_API_BASE_URL}/api/scheduler/remove-resource-schedule`,
          { data: objData }
        )
        .then((res: AxiosResponse<IDefaultResponseModel, any>) => res);
    },
    getAvailableHourByDateAndTeamId: (
      objData: AvailableHoursByDateAndTeamIdRequest
    ): HttpPromise<AvailableHoursByDateAndTeamIdResponse[]> => {
      return httpClient
        .post<any[]>(
          `${process.env.REACT_APP_API_BASE_URL}/api/maintenance-order/get-maintenance-order-weekly-availability`,
          { data: objData }
        )
        .then((res) => res);
    }
  }
};
export default ScheduleCalendarService();
